import React from "react";
import { Link } from "gatsby";
import Image from "../../components/Image";
import { AttentionColorMaps, ClarityColorMaps } from "./ColorMaps";

const Anchor = (props) => (
  <a target="_blank" rel="noreferrer" style={{ color: "#3E21DE" }} {...props}>
    {props.children}
  </a>
);
export default {
  home: [
    {
      id: 1,
      title: "What is an Attention Map?",
      description: (
        <>
          The Attention Map shows you on where the attention of potential users
          will be drawn, during the first seconds of their interaction.
          <br />
          <br />
          It maps what parts of the page are most and least actively engaged by
          using a coloured overlay.
          <br />
          <AttentionColorMaps />
        </>
      ),
      link:
        "https://www.notion.so/How-to-interpret-Attention-Maps-0a4c60d4d33347c1a0e1cd9c4c7497d4",
      text: "Learn More about Attention Maps",
    },
    {
      id: 0,
      title: "What is a Clarity Prediction?",
      description: (
        <>
          <b>Clarity Score</b> is an instant design metric evaluating how clear
          and appealing your design is. It ranges between 0 - 100.
          <br />
          <br />
          <b>Clarity Maps</b> are showing you which areas of our design have a
          negative or a positive contribution to your Clarity Score
          <br />
          <ClarityColorMaps />
        </>
      ),
      link:
        "https://www.notion.so/How-to-interpret-Clarity-Score-and-Maps-1831412bfd6d44ac9df4488913b20889",
      text: "Learn More about Clarity",
    },
    {
      id: 2,
      title: "How accurate is the technology?",
      description: (
        <>
          We generate our results using state of the art Artificial Intelligence
          technology.
          <br />
          <br />
          Diverse data from our proprietary eye tracking platform, Loceye, and
          open datasets help us predict user behavior in every kind of design.
          Our Attention Map algorithm is scoring more than 84% accuracy.
        </>
      ),
      link: "http://bit.ly/2NFa8i6",
      text: "Check out our accuracy statement",
    },
    // {
    //   id: 3,
    //   title: "How safe are my designs?",
    //   description:
    //     "No need to worry about security. With our technology, no-one is watching your designs except our algorithms. Once the prediction is ready, your encypted data are immediately deleted. Your top-secret projects are safe with us!",
    //   link: "/security",
    //   text: "Our Security policy"
    // },
    {
      id: 4,
      title: "Where should I report a bug?",
      description:
        "If you find a bug, or face any difficulty using our tool, you can join our Spectrum community and post your problem there. We usually answer any request in a few hours!",
      link: "https://spectrum.chat/visualeyes-designers",
      text: "Our Spectrum community",
    },
    {
      id: 5,
      title: "Do I have the right to use the heatmap for a presentation?",
      description:
        "Absolutely! There are no copyright restrictions on our attention heatmaps. Just introduce the magic to your friends 🧙‍♂️",
      link:
        "https://twitter.com/intent/tweet?text=WOW!%20Attention%20heatmaps%20inside%20my%20everyday%20design%20tool%20🔥https://www.visualeyes.design",
      text: "Tell my friends",
    },
    {
      id: 6,
      title: "Do you offer custom solutions for teams or individual designers?",
      description:
        "Feel free to contact our team and describe us your problem. We'd be glad to help you out!",
      link: "/contact",
      text: "Contact us",
    },
  ],
  pricing: [
    // {
    //   id: 0,
    //   title: "What are Credits and what happens if I run out of them?",
    //   description:
    //     "Each credit accounts for one image tested with VisualEyes. We will inform you if you run out of credits and then you can use VisualEyes with a pay as you go approach ($0.1 per credit). This applies to Pro account holders only."
    // },
    {
      id: 2,
      title: "What is your refund policy?",
      description:
        "We don’t offer refunds on monthly plans. If you’re on a monthly plan, you must cancel before your monthly renewal date. If you’re on a annual plan and you forget to cancel your subscription before the renewal date, please reach out to info@loceye.io within fifteen days of the renewal date to request a refund.",
    },
    {
      id: 3,
      title:
        "Do you offer discounts for non-profits, educational institutions, or students?",
      description:
        "Absolutely! Please contact our support team by clicking the link bellow.",
      link: "mailto:info@loceye.io?subject=VisualEyes Discount Request",
      text: "Support",
    },
    {
      id: 4,
      title:
        "What happens if I don't use all my subscription credits within a month?",
      description:
        "The credits are noncumulative, meaning that unused credits will not be added to the next month.",
    },
    {
      id: 5,
      title: "Do you offer an API to developers?",
      description:
        "Yes, we offer APIs for custom solutions to Enterprise clients or startups that are about to change the design game. Feel free to contact us and make a request.",
      link: "/contact",
      text: "Contact Us",
    },
    {
      id: 1,
      title: "Do you keep any data at your servers?",
      description: (
        <>
          Any media submitted by the user may be kept to validate and improve
          our algorithms. The media is only processed by employees of Loceye and
          is under no circumstances shared with any third-party vendors. A
          detailed explanation as to how the data is handled can be found in our{" "}
          <Anchor href="https://d34cuim5v38p5e.cloudfront.net/legal/VisualEyes/Data-Protection-Policy.pdf">
            Data Privacy Policy
          </Anchor>
          .
          <br />
          <br />
          Customers that are interested in custom Legal Terms - including but
          not limited to data handling - can{" "}
          <Link to="/contact" style={{ color: "#3E21DE" }}>
            contact us
          </Link>{" "}
          and learn more about our Enterprise solutions!
        </>
      ),
    },
    {
      id: 6,
      title: "Can I cancel my plan at any time?",
      description: (
        <>
          Yes, you can cancel your plan at any time! To cancel your
          subscription, please visit the subscription page on{" "}
          <Anchor href="https://app.visualeyes.design/subscription">
            the subscription page on the VisualEyes web app
          </Anchor>
          .
          <br /> <br />
          For more information regarding your subscription, feel free to{" "}
          <Link to="/contact" style={{ color: "#3E21DE" }}>
            contact us
          </Link>{" "}
        </>
      ),
    },
  ],
};
