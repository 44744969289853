import React from "react";
import PropTypes from "prop-types";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import Box from "reusecore/src/elements/Box";
import Text from "reusecore/src/elements/Text";
import Heading from "reusecore/src/elements/Heading";
import Card from "reusecore/src/elements/Card";
import Button from "reusecore/src/elements/Button";
import Image from "../../../components/Image";
import AvailablePlugins from "../../../components/AvailablePluginsPurple";
import FeatureBlock from "../../../components/FeatureBlock";
import Container from "../../../components/Container";
import ValuePointWrapper, { SectionObject } from "./ValuePoint.style";
import { FiArrowRight } from "react-icons/fi";
import { buttonStyle } from "reusecore/src/theme/customVariant";
import { navigate } from "gatsby";
import redirect from "../../../utils/redirect";
import { gaVisitor } from "../../../utils/analytics";

const ValuePoint = ({
  titleStyle,
  subtitleStyle,
  descriptionStyle,
  textAreaStyle,
  imageWrapper,
  title,
  subtitle,
  content,
  index,
  left,
  image,
  to,
  external,
  ctaName,
  eventName,
}) => {
  return (
    <ValuePointWrapper id={`feature-${index}`} left={left}>
      <SectionObject left={left}>
        <Card className="objectWrapper" {...imageWrapper}>
          <Fade left={!left} right={left}>
            <img src={image} alt={`feature-${index}-image`} />
            {/* <Image filename={image} alt={`feature-${index}-image`} /> */}
          </Fade>
        </Card>
      </SectionObject>
      <Container>
        <Box {...textAreaStyle(left)}>
          <FeatureBlock
            title={<Heading as="h2" content={title} {...titleStyle} />}
            subtitle={<Heading as="h3" content={subtitle} {...subtitleStyle} />}
            description={
              <>
                {content.map((text) => (
                  <Text content={text} {...descriptionStyle} />
                ))}
              </>
            }
            additionalContent={
              <div>
                {index === 0 ? (
                  <AvailablePlugins
                    id="value-points"
                    style={{ margin: "1rem 0 2rem 0" }}
                  />
                ) : null}
                <Button
                  id={`btn-value-point-${title.split(" ").join("-")}`}
                  title={ctaName}
                  icon={<FiArrowRight />}
                  variant="textButton"
                  iconPosition="right"
                  onClick={() => {
                    gaVisitor(eventName);
                    external ? redirect(to, eventName) : navigate(to);
                  }}
                  style={{
                    textTransform: "capitalize",
                    fontSize: "18px",
                    marginTop: "1rem",
                  }}
                />
              </div>
            }
          />
        </Box>
      </Container>
    </ValuePointWrapper>
  );
};

ValuePoint.propTypes = {
  titleStyle: PropTypes.object,
  descriptionStyle: PropTypes.object,
  btnStyle: PropTypes.object,
};

ValuePoint.defaultProps = {
  textAreaStyle: (isLeft) => ({
    width: ["100%", "100%", "45%"],

    ml: [0, 0, isLeft ? "auto" : "50%"],
    mr: [0, 0, isLeft ? "50%" : "auto"],
  }),
  imageWrapper: {
    boxShadow: "none",
    width: "100%",
  },
  titleStyle: {
    fontSize: ["20px", "24px", "24px", "24px", "30px"],
    fontWeight: "bold",
    color: "#32325d",
    mb: "4px",
    letterSpacing: "-0.010em",
    maxWidth: ["100%", "100%", "100%", "440px", "440px"],
    lineHeight: "1.5",
  },
  subtitleStyle: {
    fontSize: ["18px", "20px"],
    fontWeight: "400",
    color: "rgba(52, 61, 72, 0.5)",
    mb: "32px",
    maxWidth: ["100%", "100%", "100%", "440px", "440px"],
    lineHeight: "1.5",
  },
  descriptionStyle: {
    fontSize: "20px",
    color: "#343d48cc",
    lineHeight: "1.8",
    mb: "1rem",
    maxWidth: ["100%", "100%", "100%", "440px", "440px"],
  },
};

export default ValuePoint;
