import React from "react";
import "./styles.css";

const InfoIcon = ({ size = "79px" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 79 79"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="11"
      y="11"
      width="56"
      height="56"
      rx="28"
      fill="#28158D"
      className="innerCircle"
    />
    <rect x="11" y="11" width="56" height="56" rx="28" fill="#28158D" />
    <path
      d="M36.25 47.2493H41.75"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M41.749 43.7284V44.7056H36.249V43.7284C36.249 42.7595 35.7999 41.8547 35.0528 41.2369C33.7594 40.1653 32.9087 38.5795 32.8198 36.7902C32.6502 33.3609 35.4689 30.346 38.9019 30.2937C42.3641 30.2397 45.1874 33.0291 45.1874 36.4785C45.1874 38.4053 44.3047 40.1232 42.9224 41.2562C42.1808 41.8639 41.749 42.7696 41.749 43.7284Z"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M35.8281 42.1615H42.1623"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default InfoIcon;
