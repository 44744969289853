import React from "react";
import PropTypes from "prop-types";
import Box from "reusecore/src/elements/Box";
import Heading from "reusecore/src/elements/Heading";
import Image from "../../components/Image";
import Container from "../../components/Container";
import UserLogos from "./UserLogos";
import ClientsRow from "../CTASection/ClientsRow";
import ClientsRowMobile from "../CTASection/ClientsRowMobile";
import useWindowSize from "../../hooks/useWindowSize";

const CustomersSection = ({
  sectionWrapper,
  row,
  title,
  textArea,
  imageArea,
  ImageOne,
  dark
}) => {
  const size = useWindowSize();
  console.log(size);

  const width = undefined || 1000;
  console.log(width);

  return (
    <Box
      {...sectionWrapper}
      style={{
        borderBottomLeftRadius: "50% 20%",
        borderBottomRightRadius: "50% 20%",
        backgroundColor: "#fff"
      }}
    >
      <Container>
        <Box {...row}>
          <Box {...textArea}>
            <Heading
              as={"p"}
              {...title(dark)}
              content="Τeams in the world's biggest brands are using VisualEyes"
            />
          </Box>
          <Box {...imageArea}>
            {width > 550 ? (
              <ClientsRow width="100%" style={{ margin: "1rem 0" }} />
            ) : (
              <ClientsRowMobile
                width="calc(100% - 4rem)"
                style={{ margin: "1rem 2rem" }}
              />
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

CustomersSection.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
  imageArea: PropTypes.object,
  ImageOne: PropTypes.object
};

CustomersSection.defaultProps = {
  sectionWrapper: {
    as: "section",
    pt: ["30px", "60px"],
    pb: ["30px", "60px"],
    // background: "primary",
    borderBottomLeftRadius: "50% 20%",
    borderBottomRightRadius: "50% 20%",
    backgroundColor: "#fff"
  },
  row: {
    flexBox: true,
    flexWrap: "wrap",
    flexDirection: "column",
    alignItems: "center"
  },
  textArea: {
    width: ["100%", "100%", "80%", "80%"]
  },
  imageArea: {
    width: ["100%", "100%", "100%"],
    mb: ["35px", "35px", "40px", "40px"]
  },
  title: dark => ({
    fontSize: ["18px", "24px"],
    fontWeight: "500",
    color: dark ? "#33325C" : "#757575",
    mb: ["32px", "48px"],
    textAlign: "center",
    lineHeight: "1.25"
  }),
  description: {
    fontSize: ["16px", "18px"],
    color: "textColor",
    lineHeight: "2.1",
    textAlign: "center",
    mb: ["35px", "35px", "40px", "60px"]
  },
  ImageOne: {
    ml: "auto",
    mr: "auto",
    width: "100%"
  },
  btnStyle: {
    minWidth: "156px",
    fontSize: "16px",
    fontWeight: "500",
    colors: "primaryWithBg"
  },
  outlineBtnStyle: {
    minWidth: "156px",
    fontSize: "16px",
    fontWeight: "500",
    color: "#0f2137"
  }
};

export default CustomersSection;
