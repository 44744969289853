import React from "react";
import Block from "./Block";
import SectionLayout from "../../components/SectionLayout";
import data from "./data.js";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;

  @media (max-width: 767px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto 2rem;
  }
`;

const Blocks = ({}) => {
  return (
    <SectionLayout sectionId="personas" title="We are proudly helping" as="h2">
      <Wrapper>
        {data.results.map((block, index) => (
          <Block
            key={index}
            index={index}
            title={block.title}
            subtitle={block.subtitle}
            content={block.content}
            image={block.image}
            icon={block.icon}
            left={index % 2}
            additional={block.additional}
            ctaText={block.ctaText}
            to={block.to}
            external={block.external}
            eventName={block.eventName}
          />
        ))}
      </Wrapper>
    </SectionLayout>
  );
};

export default Blocks;
