import React from "react";

export function FreelancerIcon({ width, color }) {
  return (
    <svg
      width={width}
      viewBox="0 0 252 590"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M251.505 406.929L212.657 429.498L177.828 449.764L127.064 479.268L0.505859 406.217L51.2697 376.713L86.1129 356.476L124.961 333.883L251.505 406.929Z"
        fill="#172182"
      />
      <g clip-path="url(#clip0-freelancer)">
        <path
          d="M251.325 237.31L130.858 307.319L0.402344 231.973L120.869 161.965L251.325 237.31Z"
          fill="#172182"
        />
        <path
          d="M32.0547 231.957L120.899 180.328L219.687 237.362L130.843 288.991L32.0547 231.957Z"
          fill="#4F31F5"
        />
        <path
          d="M251.323 237.305L251.037 410.742L130.57 480.75L130.856 307.313L251.323 237.305Z"
          fill="white"
        />
        <path
          d="M228.209 276.324L228.032 299.39L153.691 342.594L153.868 319.528L228.209 276.324Z"
          fill="#4F31F5"
        />
        <path
          d="M228.209 370.527L228.032 393.593L153.691 436.797L153.868 413.731L228.209 370.527Z"
          fill="#4F31F5"
        />
        <path
          d="M228.209 323.07L228.032 346.136L153.691 389.34L153.868 366.274L228.209 323.07Z"
          fill="#4F31F5"
        />
        <path
          d="M130.853 307.322L130.567 480.759L0.111328 405.414L0.396832 231.977L130.853 307.322Z"
          fill="#4F31F5"
        />
        <path
          d="M32.041 231.957L44.8211 239.337L120.886 195.135V180.328L32.041 231.957Z"
          fill="#3E21DE"
        />
        <path
          d="M120.893 180.328V195.135L206.9 244.794L219.681 237.374L120.893 180.328Z"
          fill="#3E21DE"
        />
        <path
          d="M83.2309 388.415L83.1168 453.298L32.7324 424.187L32.8693 359.305L83.2309 388.415Z"
          fill="#172182"
        />
      </g>
      <ellipse
        opacity="0.05"
        cx="125.5"
        cy="552"
        rx="67.5"
        ry="38"
        fill="#4F31F5"
      />

      <defs>
        <clipPath id="clip0-freelancer">
          <rect
            x="0.113281"
            y="162"
            width="251.214"
            height="318.762"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export function DigitalAgencyIcon({ width, color }) {
  return (
    <svg
      width={width}
      viewBox="0 0 252 590"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0-digital)">
        <g clip-path="url(#clip1-digital)">
          <path
            d="M251.609 404.587L212.934 427.055L178.262 447.23L127.725 476.602L1.73242 403.878L52.2692 374.505L86.9565 354.359L125.631 331.867L251.609 404.587Z"
            fill="#172182"
          />
          <path
            d="M127.707 234.29L127.298 480.032L1.34375 407.312L1.75295 161.57L127.707 234.29Z"
            fill="#4F31F5"
          />
          <path
            d="M127.704 234.286L251.603 162.285L251.194 408.027L127.295 480.028L127.704 234.286Z"
            fill="white"
          />
          <path
            d="M1.73047 161.568L125.629 89.5664L251.583 162.286L127.704 234.287L1.73047 161.568Z"
            fill="#E5920E"
          />
          <path
            d="M251.607 162.286L212.932 184.754L178.26 204.929L127.723 234.301L1.73047 161.577L52.2673 132.205L86.9546 112.058L125.629 89.5664L251.607 162.286Z"
            fill="#172182"
          />
          <path
            d="M127.912 153.418L127.702 243.824L63.8047 206.929L64.0141 116.523L127.912 153.418Z"
            fill="#4F31F5"
          />
          <path
            d="M114.16 174.066L114.065 226.406L77.5645 205.328L77.6596 153.035L114.16 174.066Z"
            fill="#172182"
          />
          <path
            d="M127.914 153.427L190.77 116.898L190.561 207.304L127.705 243.833L127.914 153.427Z"
            fill="white"
          />
          <path
            d="M64.0059 116.529L126.862 80L190.76 116.895L127.904 153.424L64.0059 116.529Z"
            fill="#E5920E"
          />
          <path
            d="M190.771 116.905L171.148 128.325L153.543 138.555L127.905 153.457L63.998 116.562L89.6352 101.66L107.241 91.4392L126.863 80.0195L190.771 116.905Z"
            fill="#172182"
          />
          <path
            d="M141.682 173.031L141.777 225.371L178.277 204.293L178.182 152L141.682 173.031Z"
            fill="#3E21DE"
          />
          <path
            d="M1.73438 191.281V201.392L126.875 273.627V263.515L1.73438 191.281Z"
            fill="#172182"
          />
          <path
            d="M251.588 202.121L251.612 191.996L127.713 263.997L126.871 263.521V273.633L127.713 274.108L251.588 202.121Z"
            fill="#3E21DE"
          />
          <path
            d="M1.73438 226.148V236.26L126.875 308.494V298.383L1.73438 226.148Z"
            fill="#172182"
          />
          <path
            d="M251.588 236.989L251.612 226.863L127.713 298.864L126.871 298.388V308.5L127.713 308.975L251.588 236.989Z"
            fill="#3E21DE"
          />
          <path
            d="M127.713 343.862L251.588 271.875L251.612 261.75L127.713 333.751L126.871 333.275V343.386L127.713 343.862Z"
            fill="#3E21DE"
          />
          <path
            d="M1.73438 271.139L126.875 343.373V333.262L1.73438 261.027V271.139Z"
            fill="#172182"
          />
          <path
            d="M127.713 378.727L251.588 306.754L251.612 296.629L127.713 368.611L126.871 368.135V378.246L127.713 378.727Z"
            fill="#3E21DE"
          />
          <path
            d="M1.73438 306.002L126.875 378.236V368.13L1.73438 295.891V306.002Z"
            fill="#172182"
          />
          <path
            d="M127.713 413.6L251.588 341.614L251.612 331.488L127.713 403.489L126.871 403.013V413.125L127.713 413.6Z"
            fill="#3E21DE"
          />
          <path
            d="M1.73438 340.873L126.875 413.107V402.996L1.73438 330.762V340.873Z"
            fill="#172182"
          />
          <path
            d="M1.73438 375.751L126.875 447.99V437.879L1.73438 365.645V375.751Z"
            fill="#172182"
          />
          <path
            d="M127.713 448.468L251.588 376.481L251.612 366.355L127.713 438.356L126.871 437.881V447.992L127.713 448.468Z"
            fill="#3E21DE"
          />
        </g>
      </g>
      <ellipse
        opacity="0.05"
        cx="125.5"
        cy="552"
        rx="67.5"
        ry="38"
        fill="#4F31F5"
      />
      <defs>
        <clipPath id="clip0-digital">
          <rect width="252" height="481" fill="white" />
        </clipPath>
        <clipPath id="clip1-digital">
          <rect x="0.666016" y="80" width="251" height="401.001" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function EnterpriseIcon({ width, color }) {
  return (
    <svg
      width={width}
      viewBox="0 0 252 590"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0-enterprise)">
        <g clip-path="url(#clip1-enterprise)">
          <path
            d="M251.554 85.951L126.773 158.468L1.17578 85.951L125.957 13.4336L251.554 85.951Z"
            fill="#3C23C6"
          />
          <path
            d="M251.553 85.9531L251.258 407.979L126.477 480.497L126.771 158.471L251.553 85.9531Z"
            fill="white"
          />
          <path
            d="M126.776 158.396L126.481 480.45L0.878906 407.932L1.17382 85.8789L126.776 158.396Z"
            fill="#4F31F5"
          />
          <path
            d="M228.539 59.7612L126.669 118.964L24.1309 59.7612L126.001 0.558594L228.539 59.7612Z"
            fill="#172182"
          />
          <path
            d="M205.003 59.7613L126.588 105.331L47.6621 59.7613L126.077 14.1914L205.003 59.7613Z"
            fill="#4F31F5"
          />
          <path
            d="M126.076 29.1542L192.093 67.2696L204.993 59.7691L126.067 14.1992L47.6523 59.7691L60.5525 67.2236L126.076 29.1542Z"
            fill="#3E21DE"
          />
          <path
            d="M228.541 59.7578L228.302 82.2041L126.432 141.407L126.676 118.96L228.541 59.7578Z"
            fill="white"
          />
          <path
            d="M126.669 118.964L126.425 141.41L23.8867 82.2119L24.1309 59.7656L126.669 118.964Z"
            fill="#4F31F5"
          />
          <path
            d="M15.123 371.848L15.335 140.566L28.0785 147.938V379.33L15.123 371.848Z"
            fill="#172182"
          />
          <path
            d="M42.1348 387.372L42.3467 156.09L55.0903 163.461V394.854L42.1348 387.372Z"
            fill="#172182"
          />
          <path
            d="M69.1582 402.899L69.3701 171.617L82.1137 178.989V410.381L69.1582 402.899Z"
            fill="#172182"
          />
          <path
            d="M96.1621 418.434L96.3741 187.152L109.118 194.524V425.916L96.1621 418.434Z"
            fill="#172182"
          />
          <path
            d="M239.758 371.848L239.546 140.566L226.803 147.938V379.33L239.758 371.848Z"
            fill="#3E21DE"
          />
          <path
            d="M212.746 387.372L212.535 156.09L199.791 163.461V394.854L212.746 387.372Z"
            fill="#3E21DE"
          />
          <path
            d="M185.723 402.899L185.511 171.617L172.768 178.989V410.381L185.723 402.899Z"
            fill="#3E21DE"
          />
          <path
            d="M158.719 418.434L158.507 187.152L145.764 194.524V425.916L158.719 418.434Z"
            fill="#3E21DE"
          />
        </g>
      </g>
      <ellipse
        opacity="0.05"
        cx="125.5"
        cy="552"
        rx="67.5"
        ry="38"
        fill="#4F31F5"
      />
      <defs>
        <clipPath id="clip0-enterprise">
          <rect width="252" height="481" fill="white" />
        </clipPath>
        <clipPath id="clip1-enterprise">
          <rect x="0.333984" width="251.712" height="480.899" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
