import styled from "styled-components";

export const Card = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  & > svg {
    margin-right: 1.5rem;
  }

  background-color: white;
  padding: 1.5rem;
  margin: 0.5rem;
  border-radius: 10px;
  ${"" /* box-shadow:  */}
  border: solid 1px #f1f1f1;

  & > * {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    color: #343d48;
    font-weight: 500;

    line-height: 1.8;
  }

  width: calc(50% - 1rem);

  @media (max-width: 767px) {
    width: calc(100% - 1rem);
  }
`;

export const CardsWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;

  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }

  .column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
  }
`;
