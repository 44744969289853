import { APP_SIGN_UP_URL } from "../../utils/constants";

export default [
  {
    title: "UX Designers",
    subtitle: "Research Insights with one click",
    content: [
      "Replace time-consuming Eye Tracking studies with Attention Heatmaps and preference testing with our Clarity Score. VisualEyes makes it easy to generate user insights with the touch of a button. Oh, and all that within your favourite design tools.",
    ],
    // image: "/home-ux.jpg",
    image:
      "https://d34cuim5v38p5e.cloudfront.net/visualeyes/website/Personas+-+UX.jpg",
    external: true,
    to: APP_SIGN_UP_URL,
    ctaName: "Inspect design's Visual Hierarchy",
    eventName: "ux_designer",
  },
  {
    title: "Product Managers",
    subtitle: "Better designs mean higher KPIs",
    content: [
      "Building the right digital product is not easy. Increase your KPIs and your developers’ productivity by testing designs before every release. Fewer iterations mean more love from your team and your users.",
    ],
    // image: "/valuePoints/home-managers.png",
    image:
      "https://d34cuim5v38p5e.cloudfront.net/visualeyes/website/Personas+-+Manager.jpg",

    external: true,
    to: APP_SIGN_UP_URL,
    ctaName: "Speed up product cycles",
    eventName: "product_owner",
  },
  {
    title: "Digital Marketers",
    subtitle: "Spend your campaign money more efficiently",
    content: [
      "VisualEyes will help you thrive in the attention economy. In the never-ending fight for customers' attention, be sure to use data to stand out from your competitors and increase the performance of each of your campaigns.",
    ],
    // image: "/home-advertisers.jpg",
    image:
      "https://d34cuim5v38p5e.cloudfront.net/visualeyes/website/Personas+-+Marketeer.jpg",

    external: true,
    to: APP_SIGN_UP_URL,
    ctaName: "Pre-test your campaign",
    eventName: "marketer",
  },
];
