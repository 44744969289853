import React, { useState, useEffect } from "react";
import Block from "./Block";
import SectionLayout from "../../components/SectionLayout";
import data from "./data.js";
import Image from "../../components/Image";

function findActiveScreen(selected) {
  return data.demo.find((metric) => metric.id === selected).url;

  switch (selected) {
    case "original":
      return data.demo[0].url;

    case "attention":
      return data.demo[1].url;

    case "clarity":
      return data.demo[2].url;

    case "aoi":
      return data.demo[3].url;

    default:
      return data.demo[0].url;
  }
}

function findActiveInfo(selected) {
  return data.demo.find((metric) => metric.id === selected).content;

  switch (selected) {
    case "original":
      return data.demo[0].content;

    case "attention":
      return data.demo[1].content;

    case "clarity":
      return data.demo[2].content;

    case "aoi":
      return data.demo[3].content;

    default:
      return data.demo[0].image;
  }
}

const Blocks = ({}) => {
  const [selected, setSelected] = useState("attention");
  const [screen, setScreen] = useState(findActiveScreen(selected));
  const [info, setInfo] = useState(findActiveInfo(selected));

  useEffect(() => {
    const screen = findActiveScreen(selected);
    setScreen(screen);
    const info = findActiveInfo(selected);
    setInfo(info);
  }, [selected]);

  return (
    <SectionLayout
      sectionId="metrics-demo"
      title="Here's how VisualEyes works"
      style={{ paddingTop: "0" }}
      as="h2"
    >
      <Block
        metrics={data.demo}
        setSelected={setSelected}
        screen={screen}
        info={info}
        selected={selected}
      />
    </SectionLayout>
  );
};

export default Blocks;

{
  /* <DemoScreen
          key={index}
          index={index}
          title={block.title}
          content={block.content}
          image={block.image}
          left={false}
          additional={block.additional}
        /> */
}
