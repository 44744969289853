import React from "react";
import PropTypes from "prop-types";
import ValuePoint from "./ValuePoint/index";
import SectionLayout from "../../components/SectionLayout";
import data from "./data.js";

const ValuePoints = ({ as }) => {
  return (
    <SectionLayout
      sectionId="valuePoints"
      // title="Why should I use VisualEyes?"
      style={{ paddingTop: 0, paddingBottom: 0, margin: "auto 2rem" }}
      as="h1"
    >
      {data.map((feature, index) => (
        <ValuePoint
          key={index}
          index={index}
          title={feature.title}
          subtitle={feature.subtitle}
          content={feature.content}
          image={feature.image}
          to={feature.to}
          external={feature.external}
          ctaName={feature.ctaName}
          left={index % 2}
          eventName={feature.eventName}
        />
      ))}
    </SectionLayout>
  );
};

export default ValuePoints;
