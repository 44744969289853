import React from "react";
import PropTypes from "prop-types";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import Box from "reusecore/src/elements/Box";
import Text from "reusecore/src/elements/Text";
import Heading from "reusecore/src/elements/Heading";
import Card from "reusecore/src/elements/Card";
import Button from "reusecore/src/elements/Button";
import Image from "reusecore/src/elements/Image";
import FeatureBlock from "../../../components/FeatureBlock";
import Container from "../../../components/Container";
import BlockWrapper, {
  OptionsContainer,
  MetricOption,
  InfoContainer,
  InfoText,
} from "./Block.style";
import { FiArrowRight } from "react-icons/fi";
import { buttonStyle } from "reusecore/src/theme/customVariant";
import { navigate } from "gatsby";
import Img from "../../../components/Image";
// import { InfoIcon } from "../Icons";
import InfoIcon from "../InfoIcon";

const Block = ({ metrics, setSelected, screen, info, selected }) => {
  return (
    <BlockWrapper id={`metrics-demo`} left={true}>
      <OptionsContainer>
        {metrics.map((metric) => (
          <MetricOption
            isSelected={selected === metric.id}
            onClick={() => setSelected(metric.id)}
          >
            <div>{metric.icon}</div>
            <Heading as="h3" content={metric.name} />
          </MetricOption>
        ))}
      </OptionsContainer>

      <InfoContainer>
        <InfoIcon size={48} />
        <InfoText>{info}</InfoText>
      </InfoContainer>

      <img
        src={screen}
        alt={`Preview of ${selected} metric`}
        style={{
          minWidth: "200px",
          minHeight: "200px",
          width: "80%",
          margin: "0 auto",
        }}
      />
    </BlockWrapper>
  );
};

Block.propTypes = {
  titleStyle: PropTypes.object,
  descriptionStyle: PropTypes.object,
  btnStyle: PropTypes.object,
};

Block.defaultProps = {
  textAreaStyle: (isLeft) => ({
    width: ["100%", "100%", "45%"],

    ml: [0, 0, isLeft ? "auto" : "50%"],
    mr: [0, 0, isLeft ? "50%" : "auto"],
  }),
  imageWrapper: {
    boxShadow: "none",
    width: "100%",
  },
  titleStyle: {
    fontSize: ["20px", "24px", "24px", "24px", "30px"],
    fontWeight: "900",
    color: "#32325d",
    letterSpacing: "-0.010em",
    mb: "20px",
    maxWidth: ["100%", "100%", "100%", "440px", "440px"],
    lineHeight: "1.5",
  },
  descriptionStyle: {
    fontSize: "18px",
    color: "#343d48cc",
    lineHeight: "1.75",
    mb: "1rem",
    maxWidth: ["100%", "100%", "100%", "440px", "440px"],
  },
};

export default Block;
