import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Box from "reusecore/src/elements/Box";
import Text from "reusecore/src/elements/Text";
import Heading from "reusecore/src/elements/Heading";
import {
  Accordion,
  AccordionItem,
  AccordionTitle,
  AccordionBody,
  IconWrapper,
  OpenIcon,
  CloseIcon
} from "../../components//Accordion";
import { Icon } from "react-icons-kit";
import { plus } from "react-icons-kit/entypo/plus";
import { minus } from "react-icons-kit/entypo/minus";
import FaqSectionWrapper from "./faqSection.style";
import data from "./data";
import SectionLayout from "../../components/SectionLayout";

const FaqSection = ({ titleStyle, descriptionStyle, isPricing }) => {
  let faqs = [];

  if (isPricing) {
    faqs = data.pricing;
  } else {
    faqs = data.home;
  }

  return (
    <SectionLayout sectionId="faq" title="Frequently Asked Questions" as="h2">
      <FaqSectionWrapper isPricing={isPricing}>
        <Box className="row">
          <Accordion>
            <Fragment>
              {faqs.map((faqItem, index) => (
                <AccordionItem key={index} expanded={faqItem.expend}>
                  <Fragment>
                    <AccordionTitle>
                      <Fragment>
                        <Heading
                          as="h3"
                          content={faqItem.title}
                          {...titleStyle}
                        />
                        <IconWrapper>
                          <OpenIcon>
                            <Icon
                              icon={minus}
                              size={18}
                              style={{ color: "#3e21de" }}
                            />
                          </OpenIcon>
                          <CloseIcon>
                            <Icon
                              icon={plus}
                              size={18}
                              style={{ color: "#3e21de" }}
                            />
                          </CloseIcon>
                        </IconWrapper>
                      </Fragment>
                    </AccordionTitle>
                    <AccordionBody>
                      <Text
                        content={faqItem.description}
                        {...descriptionStyle}
                      />
                      {faqItem.link && (
                        <a
                          href={faqItem.link}
                          style={{ color: "#3E21DE" }}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {faqItem.text}
                        </a>
                      )}
                    </AccordionBody>
                  </Fragment>
                </AccordionItem>
              ))}
            </Fragment>
          </Accordion>
        </Box>
      </FaqSectionWrapper>
    </SectionLayout>
  );
};

// FaqSection style props
FaqSection.propTypes = {
  sectionHeader: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  buttonWrapper: PropTypes.object,
  button: PropTypes.object
};

// FaqSection default style
FaqSection.defaultProps = {
  // accordion title default style
  titleStyle: {
    fontSize: ["18px", "22px"],
    fontWeight: "500",
    color: "#0f2137",
    letterSpacing: "-0.025em",
    mb: "0"
  },
  // accordion description default style
  descriptionStyle: {
    fontSize: ["17px", "18px"],
    color: "#5d646d",
    lineHeight: "1.75",
    mb: "6",
    fontWeight: "400"
  }
};

export default FaqSection;
