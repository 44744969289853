import styled from "styled-components";

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;

  margin-bottom: 1rem;
`;

const MetricOption = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 1rem;

  font-size: 20px;

  & > h3 {
    margin: 0
    color: ${(p) => (p.isSelected ? "#3E21DE" : "#B2ADCC")};
    font-weight: 500;
    align-text: center;
    text-align: center;
  }

  & > div {
    background-color: ${(p) => (p.isSelected ? "#3E21DE" : "#F6F5F9")};
    border-radius: 50%;
    margin-bottom: 1rem;
    padding: 1rem;
    width: ${2 * 16 + 32}px;
    height: ${2 * 16 + 32}px;

    @media (max-width: 767px) {
      padding: 1rem;
      width: ${2 * 16 + 24}px;
      height: ${2 * 16 + 24}px;
    }
  }
  & > div > svg {
    fill: ${(p) => (p.isSelected ? "white" : "#746BA2")};

    @media (max-width: 767px) {
      width: 1.5rem !important;
      height: 1.5rem !important;
    }
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: 767px) {
    ${"" /* flex-direction: column; */}

    align-items: flex-start;
  }

  background-color: #f3f1fd;
  border-radius: 8px;
  padding: 1rem;
  width: calc(100% - 4rem);
  width: 80%;
  margin: 0 auto;
  margin-bottom: 1rem;

  & > svg {
    min-width: 40px;
  }
`;

const InfoText = styled.p`
  font-size: 18px;
  font-weight: 500;
  color: #28158d;
  margin: 0;
  margin-left: 2rem;
  line-height: 1.7;
`;

const BlockWrapper = styled.section`
  min-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
  @media only screen and (max-width: 1200px) {
    min-height: 500px;
    margin-bottom: 20px;
  }
  @media only screen and (max-width: 991px) {
    min-height: 370px;
    margin-bottom: 20px;
  }
  @media (max-width: 767px) {
    min-height: auto;
    display: block;
  }

  margin: 0;
  ${"" /* left: 0;
   ${props => props.index === 0 && `background-color: #9b8cee11;`}
   ${props => props.index === 1 && `background-color: #9b8cee33;`}
   ${props => props.index === 2 && `background-color: #9b8cee55;`} */}
`;

const SectionObject = styled.div`
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;

  ${(props) => (props.left ? "right: 0" : "left: 0;")};

  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 767px) {
    width: 100%;
    position: relative;
    height: auto;
    top: auto;
    left: auto;
  }
  img {
    max-width: 80%;
    height: auto;
  }
  .objectWrapper {
    ${(props) => (props.left ? "margin-right: auto;" : "margin-left: auto;")};

    position: relative;
    .dashboardWrapper {
      position: absolute;
      top: 4vw;
      left: 0;
      width: 80%;
      padding: 1rem;
    }
  }
`;

export {
  SectionObject,
  OptionsContainer,
  MetricOption,
  InfoContainer,
  InfoText,
};

export default BlockWrapper;
