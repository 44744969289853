import styled from "styled-components";
import Box from "reusecore/src/elements/Box";

const BlockWrapper = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  && > div:first-child > div {
    width: 200px;
    height: 200px;
  }

  @media only screen and (max-width: 1200px) {
    margin-bottom: 20px;
  }
  @media only screen and (max-width: 991px) {
    margin-bottom: 20px;
  }
  @media (max-width: 767px) {
    display: block;
    width: 100%;
    justify-content: center;
  }
`;

export default BlockWrapper;

export const ContentWrapper = styled(Box)`
  @media (max-width: 767px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
