import React from "react";
import {
  FreelancerIcon,
  DigitalAgencyIcon,
  EnterpriseIcon
} from "./BuildingIcons";
import { APP_SIGN_UP_URL } from "../../utils/constants";

export default {
  results: [
    {
      title: "Freelancers",
      subtitle: "Faster and cheaper user testing",
      content: [
        "Save time and money by ensuring your design is effective and clear. Use data to reduce back and forth with your clients and establish better communication."
      ],
      image: "plans-freelancers.png",
      icon: <FreelancerIcon width="75" />,
      ctaText: "Get User Insights",
      external: true,
      to: APP_SIGN_UP_URL,
      eventName: "freelancer"
    },
    {
      title: "Digital Agencies",
      subtitle: "Happy clients and more leads",
      content: [
        "Build products your clients love. Attract leads by using examples of how their current designs could be improved. Show your clients your efficiency in screen design. "
      ],
      image: "plans-digital-agencies.png",
      icon: <DigitalAgencyIcon width="75" />,
      ctaText: "Help Your Clients Grow",
      external: true,
      to: APP_SIGN_UP_URL,
      eventName: "agency"
    },
    {
      title: "Enterprises",
      subtitle: "Invest in design and grow faster",
      content: [
        "Companies actively investing in design, increase their revenues and shareholder returns at nearly twice the rate of their industry counterparts."
      ],
      image: "plans-enterprise.png",
      icon: <EnterpriseIcon width="75" />,
      ctaText: "Contact Us",
      external: false,
      to: "/contact",
      eventName: "enterprise"
    }
  ]
};
