import React from "react";

export function ABTestIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      fill="none"
      viewBox="0 0 64 64"
    >
      <rect width="64" height="64" fill="#3E21DE" opacity="0.1" rx="24"></rect>
      <path
        stroke="#3E21DE"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M36.394 37.5H33.9v-2.215h2.492a1.108 1.108 0 01.002 2.215z"
      ></path>
      <path
        stroke="#3E21DE"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M33.9 35.285V33h2.213a1.143 1.143 0 010 2.286M31 31l-2.25-4.5L26.5 31M26.897 30.206h3.706"
      ></path>
      <path
        stroke="#3E21DE"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M38.158 41H25.842A2.841 2.841 0 0123 38.158V25.842A2.841 2.841 0 0125.842 23h12.316A2.841 2.841 0 0141 25.842v12.316A2.841 2.841 0 0138.158 41z"
      ></path>
    </svg>
  );
}

export function PreferenceTest() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      fill="none"
      viewBox="0 0 64 64"
    >
      <rect
        width="64"
        height="64"
        fill="#F95454"
        fillOpacity="0.1"
        rx="24"
      ></rect>
      <path
        stroke="#F95454"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M41 29.333C41 26.388 38.538 24 35.5 24S30 26.388 30 29.333c0 1.221.895 2.21 2 2.21s2-.99 2-2.21C34 26.388 31.538 24 28.5 24S23 26.388 23 29.333C23 35.373 30.222 40 32 40c1.778 0 9-4.627 9-10.667z"
      ></path>
    </svg>
  );
}

export function Diamond() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      fill="none"
      viewBox="0 0 64 64"
    >
      <rect
        width="64"
        height="64"
        fill="#0C30AF"
        fillOpacity="0.1"
        rx="24"
      ></rect>
      <path
        fill="#0C30AF"
        d="M36.86 24.064a.2.2 0 00-.147-.064h-9.36a.2.2 0 00-.145.062l-4.681 4.896a.2.2 0 00-.006.27l9.33 10.602a.2.2 0 00.3 0l9.328-10.543a.2.2 0 00-.003-.269l-4.617-4.954zm-9.522 5.754a.2.2 0 01.182.117l1.964 4.285c.096.21-.18.388-.332.215l-3.771-4.285a.2.2 0 01.15-.332h1.807zm7.15 0a.2.2 0 01.182.284l-2.488 5.429a.2.2 0 01-.364 0L29.33 30.1a.2.2 0 01.182-.283h4.976zm-4.325-1.454a.2.2 0 01-.148-.335l1.837-2.005a.2.2 0 01.295 0l1.838 2.005a.2.2 0 01-.148.335h-3.675zm6.317 1.57a.2.2 0 01.182-.116h1.85a.2.2 0 01.15.333l-3.84 4.338c-.152.172-.427-.006-.33-.216l1.988-4.338zm1.975-1.907a.2.2 0 01-.146.337h-1.846a.2.2 0 01-.147-.065l-2.3-2.51a.2.2 0 01.147-.335h1.808a.2.2 0 01.147.064l2.337 2.51zm-10.517-2.51a.2.2 0 01.144-.063h1.755a.2.2 0 01.148.336l-2.3 2.509a.2.2 0 01-.148.065h-1.853a.2.2 0 01-.145-.339l2.399-2.509z"
      ></path>
    </svg>
  );
}

export function ValidateAssumptions() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      fill="none"
      viewBox="0 0 64 64"
    >
      <rect
        width="64"
        height="64"
        fill="#DE5A21"
        fillOpacity="0.1"
        rx="24"
      ></rect>
      <path
        stroke="#DE5A21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M28 24l1.3-1.625a1 1 0 01.781-.375h3.839a1 1 0 01.781.375L36 24v1a1 1 0 01-1 1h-6a1 1 0 01-1-1v-1 0zM29.667 29.333L28 31l-1-1M33 38h3M33 30h3M29.667 33.333L28 35l-1-1M33 34h3"
      ></path>
      <path
        stroke="#DE5A21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M36 24h2a2 2 0 012 2v14a2 2 0 01-2 2H26a2 2 0 01-2-2V26a2 2 0 012-2h2"
      ></path>
    </svg>
  );
}

export function TestProduct() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      fill="none"
      viewBox="0 0 64 64"
    >
      <rect
        width="64"
        height="64"
        fill="#19B47C"
        fillOpacity="0.1"
        rx="24"
      ></rect>
      <path
        stroke="#19B47C"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M26.5 30a3.5 3.5 0 110-7 3.5 3.5 0 010 7zM40 41h-4a1 1 0 01-1-1v-4a1 1 0 011-1h4a1 1 0 011 1v4a1 1 0 01-1 1zM41 26.5H30M38 23.5l3 3-3 3M23 38h12M26 41l-3-3 3-3"
      ></path>
    </svg>
  );
}

export function Eyes() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      fill="none"
      viewBox="0 0 64 64"
    >
      <rect
        width="64"
        height="64"
        fill="#B0A00A"
        fillOpacity="0.1"
        rx="24"
      ></rect>
      <path
        fillRule="evenodd"
        stroke="#B0A00A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M23.874 34.748a.9.9 0 010-.913C25.62 30.88 28.81 28.333 32 28.333c3.19 0 6.38 2.547 8.126 5.5a.905.905 0 010 .917c-1.747 2.952-4.936 5.5-8.126 5.5-3.19 0-6.38-2.548-8.126-5.502z"
        clipRule="evenodd"
      ></path>
      <path
        stroke="#B0A00A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.429"
        d="M33.945 32.347a2.75 2.75 0 11-3.888 3.89 2.75 2.75 0 013.888-3.89"
      ></path>
      <path
        stroke="#B0A00A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M32 23.291v2.292M25.584 25.125l1.54 1.833M38.417 25.125l-1.54 1.833"
      ></path>
    </svg>
  );
}
