import React, { Fragment, useState, useRef, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import PropTypes from "prop-types";
import styled from "styled-components";
import { navigate } from "gatsby";
import { FiX } from "react-icons/fi";
import ReactTooltip from "react-tooltip";
import Lottie from "react-lottie";

import Box from "reusecore/src/elements/Box";
import Text from "reusecore/src/elements/Text";
import CustomImage from "reusecore/src/elements/Image";
import Heading from "reusecore/src/elements/Heading";
import Button from "reusecore/src/elements/Button";

import FeatureBlock from "../../components/FeatureBlock";
import Container from "../../components/Container";
import Spinner from "../../components/Spinner";
import CustomersSection from "../CustomersSection";
import HeroWrapper, { DiscountLabel, HeroObject } from "./HeroSection.style";
import AvailablePluginsPurple from "../../components/AvailablePluginsPurple";
import animationData from "./animationData.js";
import { download, compress, generateHeatmap } from "./demoUtilities";
import { fbTrack } from "../../utils/analytics";
import redirect from "../../utils/redirect";
import { APP_SIGN_UP_URL } from "../../utils/constants";

import { Experiment, Variant } from "@marvelapp/react-ab-test";
import { HERO_HEADER_IMAGE } from "../../utils/abTesting.js";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  // rendererSettings: {
  //   preserveAspectRatio: "xMidYMid slice"
  // }
};

const Span = styled.span`
  @media (max-width: 768px) {
    display: block;
    text-align: center;
  }
`;

const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Card = styled.div`
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  margin: 2rem;
  margin-top: 0;
  padding: 1.5rem;
  width: 60%;
  position: relative;

  @media (min-width: 768px) and (max-width: 1024px) {
    width: 80%;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    width: 100%;
  }

  & > img {
    width: 100%;
    border-radius: 3px;
    margin-bottom: 0.75rem;
  }
  & > div {
    position: absolute;
    top: 2rem;
    right: 2rem;
    width: 2rem;
    height: 2rem;
    padding: 0.5rem;
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
  }
`;

const HeroSection = ({
  row,
  col,
  titleStyle,
  btnStyle,
  descriptionStyle,
  discountText,
  discountAmount,
  outlineBtnStyle,
  inputFileContainer,
  inputFile,
  buttons,
  buttonsRow,
  uploadButton,
}) => {
  // 1. Setup AB test ref
  // const experimentRef = useRef(null);

  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const inputEl = useRef(null);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  function handleImage(e) {
    const reader = new FileReader();
    reader.readAsArrayBuffer(e.target.files[0]);
    reader.onload = (event) => {
      const blob = new Blob([event.target.result]);
      window.URL = window.URL || window.webkitURL;
      const blobURL = window.URL.createObjectURL(blob);

      const img = new Image();
      img.src = blobURL;
      img.onload = () => {
        const compressed = compress(img);
        generateHeatmap(
          encodeURI(compressed),
          (img) => setImages([...images, img]),
          (err) => {
            alert(err);
          },
          setIsLoading
        );
      };
    };
  }

  const ButtonGroup = () => (
    <div {...buttons}>
      <Box {...buttonsRow} flexBox>
        <Button
          id="hero-primary-cta"
          className="outlined"
          title={<>Visit Neurons</>}
          {...btnStyle}
          ml={0}
          onClick={() => {
            // 3. Fire the conversion event
            // experimentRef.current.win();
            redirect("https://www.neuronsinc.com/", "Hero main CTA");
          }}
        />

        {/* <AvailablePluginsPurple
          id="hero"
          hasChrome={false}
          size="small"
          text="Available for:"
          style={{ marginTop: 0, marginLeft: "1rem" }}
        /> */}
      </Box>
    </div>
  );

  return (
    <Fragment>
      <ReactTooltip place="top" type="dark" effect="solid" />

      <HeroWrapper id="banner_section">
        <Container style={{ backgroundColor: "#fff" }}>
          <Box className="row" {...row}>
            <Box className="col" {...col}>
              <FeatureBlock
                title={
                  <Heading
                    content={
                      <Span>
                        VisualEyes is now
                        <br /> part of Neurons
                      </Span>
                    }
                    {...titleStyle}
                    as="h1"
                  />
                }
                description={
                  <Text
                    content={
                      <Span style={{ lineHeight: 1.3, fontSize: "16px" }}>
                        Get rich behavioral insights in a flash with Predict,
                        and see performance gaps clearly. Supercharge your
                        campaigns with the market's leading predictive Al.
                      </Span>
                    }
                    {...descriptionStyle}
                  />
                }
                button={<ButtonGroup />}
              />
            </Box>
            <HeroObject>
              {/* 2. Define the variants
              <Experiment ref={experimentRef} name={HERO_HEADER_IMAGE}>
                <Variant name="Old">
                  <Lottie options={defaultOptions} width={"100%"} />
                </Variant>
                <Variant name="New"> */}
              <img
                src="https://storage.googleapis.com/brandflow-bucket/misc/neurons-hero.png"
                style={{ width: "100%" }}
              />
              {/* </Variant>
              </Experiment> */}
            </HeroObject>
          </Box>
        </Container>
      </HeroWrapper>

      <CustomersSection />
    </Fragment>
  );
};

HeroSection.propTypes = {
  titleStyle: PropTypes.object,
  btnStyle: PropTypes.object,
  descriptionStyle: PropTypes.object,
  contentStyle: PropTypes.object,
  discountText: PropTypes.object,
  discountAmount: PropTypes.object,
  outlineBtnStyle: PropTypes.object,
};

HeroSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: "-16px",
    mr: "-16px",
    alignItems: "center",
  },
  col: {
    pr: "16px",
    pl: "16px",
    width: [1, "100%", "50%", "45%"],
  },
  titleStyle: {
    fontSize: ["32px", "32px", "32px", "40px"],
    fontWeight: "900",
    color: "#1c1e29",
    letterSpacing: "-0.025em",
    mb: ["20px", "25px"],
    lineHeight: "1.5",
  },
  descriptionStyle: {
    fontSize: ["14px", "14px", "14px", "16px"],
    color: "#3b3e4d",
    lineHeight: ["1.5", "1.5", "1.5", "1.75"],
    mb: "0",
  },
  btnStyle: {
    minWidth: ["100%", "120px", "156px"],
    fontSize: ["13px", "16px"],
    fontWeight: "700",
    colors: "primaryWithBg",
    ml: ["0", "0", "16px", "16px"],
    mt: ["24px", "0", "0", "0"],
    mb: ["24px", "32px", "0", "0"],
  },
  outlineBtnStyle: {
    minWidth: "156px",
    fontSize: "16px",
    fontWeight: "700",
    color: "#3E21DE",
    border: "none",
    textTransform: "capitalized",
    mt: "1rem",
    ml: ["0", "16px", "16px", "16px"],
    borderBottom: "1px dotted #3E21DE",
  },
  discountAmount: {
    fontSize: "16px",
    color: "#3E21DE",
    mb: 0,
    as: "span",
    mr: "0.4em",
    fontWeight: 700,
  },
  discountText: {
    fontSize: ["13px", "16px"],
    color: "#0f2137",
    mb: 0,
    as: "span",
    fontWeight: 500,
  },
  inputFileContainer: {
    position: "relative",
    width: "100%",
  },
  inputFile: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "225px",
    opacity: "0",
    padding: "16px 0",
    cursor: "pointer",
  },
  buttons: {
    display: "flex",
    flexDirection: "column",
  },
  buttonsRow: {
    display: "flex",
    flexDirection: ["column-reverse", "column", "column", "row"],
    alignItems: ["center", "center", "flex-start", "flex-start"],
    flexWrap: ["nowrap", "nowrap", "nowrap", "wrap"],
    mb: "1rem",
  },
  uploadButton: {
    marginTop: "1rem !important",
    width: ["100%", "45%", "45%", "45%"],
  },
};

export default HeroSection;
