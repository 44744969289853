import React, { useEffect } from "react";
import HeroSection from "../containers/HeroSection";
import ValueSection from "../containers/ValuePointsSection";
import Personas from "../containers/Personas";
import FaqSection from "../containers/FaqSection";
import UseCases from "../containers/UseCases";
import MetricDemo from "../containers/MetricDemo";
import CTASection from "../containers/CTASection";
import MainLayout from "../components/MainLayout";
import PHBanner from "../components/PHBanner";

export default () => {
  return (
    <MainLayout hasGradient pathname="/">
      <HeroSection />
      <MetricDemo />
      <ValueSection />
      <Personas />
      <UseCases />
      {/* <CTASection /> */}
      {/* <FaqSection /> */}
    </MainLayout>
  );
};
