import React, { Fragment, useState, useRef } from "react";
import styled from "styled-components";
import { plugin } from "postcss";
import { APP_SIGN_UP_URL } from "../utils/constants";
import redirect from "../utils/redirect";

const PluginsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  align-items: center;
  ${"" /* justify-content: flex-end; */}

  & > p {
    font-size: ${(p) => p.fontSize}px;
    color: #343d48cc;
    margin-right: 1rem;
  }

  & > a {
    margin-right: 1rem;
  }

  & > a:last-child {
    margin-right: 0;
  }
`;
const AvailablePlugins = (props) => {
  const height = props.size === "small" ? "20" : "24";
  const fontSize = props.size === "small" ? 12 : 16;

  return (
    <PluginsContainer {...props} fontSize={fontSize}>
      <p>{props.text || "Available plugins:"}</p>
      {/* <svg
        id={`sketch-icon-${props.id}`}
        height={height}
        viewBox="0 0 100 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={() => redirect(APP_SIGN_UP_URL, "Sketch Hero")}
        style={{ cursor: "pointer", marginRight: "1rem" }}
      >
        <title>Sketch</title>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20.3191 35.9314L49.896 99.8849L0.182617 35.9314H20.3191Z"
          fill="#5940E3"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M79.4812 35.9314L49.9043 99.8849L99.6177 35.9314H79.4812Z"
          fill="#5940E3"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20.3179 35.9314H79.4716L49.8948 99.8849L20.3179 35.9314Z"
          fill="#7864E8"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M49.8948 0.488403L21.8746 3.7605L20.3179 35.9314L49.8948 0.488403Z"
          fill="#9B8CEE"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M49.8948 0.488403L77.9149 3.7605L79.4717 35.9314L49.8948 0.488403Z"
          fill="#9B8CEE"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M99.6177 35.9318L77.9245 3.76086L79.4812 35.9318H99.6177Z"
          fill="#7864E8"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.182617 35.9318L21.8758 3.76086L20.3191 35.9318H0.182617Z"
          fill="#7864E8"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M49.8948 0.488403L20.3179 35.9314H79.4716L49.8948 0.488403Z"
          fill="#C3BAF5"
        />
      </svg> */}
      <svg
        id={`figma-icon-${props.id}`}
        height={height}
        viewBox="0 0 100 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={() => redirect(APP_SIGN_UP_URL, "Figma Hero")}
        style={{ cursor: "pointer", marginRight: "1rem" }}
      >
        <title>Figma</title>
        <path
          d="M33.5712 100C42.4425 100 49.6424 92.5337 49.6424 83.3337V66.667H33.5712C24.6999 66.667 17.5 74.1337 17.5 83.3337C17.5 92.5337 24.6999 100 33.5712 100Z"
          fill="#9B8CEE"
        />
        <path
          d="M17.5 50.0005C17.5 40.8005 24.6999 33.3339 33.5712 33.3339H49.6425L49.6425 66.6672H33.5712C24.6999 66.6672 17.5 59.2005 17.5 50.0005Z"
          fill="#7864E8"
        />
        <path
          d="M17.501 16.667C17.501 7.46697 24.7009 0.000305176 33.5722 0.000305176L49.6435 0.000305176L49.6435 33.3336H33.5722C24.7009 33.3336 17.501 25.867 17.501 16.667Z"
          fill="#5940E3"
        />
        <path
          d="M49.6426 0H65.7138C74.585 0 81.7849 7.46667 81.7849 16.6667C81.7849 25.8667 74.585 33.3333 65.7138 33.3333H49.6426L49.6426 0Z"
          fill="#7864E8"
        />
        <path
          d="M81.7856 50.0005C81.7856 59.2005 74.5856 66.6672 65.7143 66.6672C56.843 66.6672 49.6431 59.2005 49.6431 50.0005C49.6431 40.8005 56.843 33.3339 65.7143 33.3339C74.5856 33.3339 81.7856 40.8005 81.7856 50.0005Z"
          fill="#9B8CEE"
        />
      </svg>
      <svg
        id={`adobe-xd-${props.id}`}
        height={height}
        viewBox="0 0 100 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={() => redirect(APP_SIGN_UP_URL, "Adobe xd Hero")}
        style={{ cursor: "pointer", marginRight: "1rem" }}
      >
        <title>Adobe XD</title>
        <path
          d="M82.292 100H17.708C7.916 100 0 91.881 0 81.838V18.1621C0 8.11897 7.916 0 17.708 0H82.292C92.084 0 100 8.11897 100 18.1621V81.838C100 91.881 92.084 100 82.292 100Z"
          fill="#7864E8"
        />
        <path
          d="M80.708 95.7256H19.292C10.96 95.7256 4.16797 88.7595 4.16797 80.2138V19.7872C4.16797 11.2415 10.96 4.27539 19.292 4.27539H80.668C89.044 4.27539 95.792 11.2415 95.792 19.7872V80.1728C95.832 88.7595 89.04 95.7256 80.708 95.7256Z"
          fill="#7864E8"
        />
        <path
          d="M39.5399 46.7933L51.2079 69.784C51.4159 70.1246 51.2919 70.4692 50.9559 70.4692H43.7039C43.2439 70.4692 43.0359 70.342 42.8279 69.9153C40.1599 64.2743 37.4519 58.6333 34.6599 52.5656H34.5759C32.0759 58.2928 29.3239 64.3194 26.6599 69.9605C26.4519 70.301 26.2439 70.4323 25.9079 70.4323H19.0399C18.6239 70.4323 18.5799 70.0917 18.7919 69.8333L30.2079 47.5236L19.1679 24.9595C18.9159 24.6189 19.1679 24.3605 19.4159 24.3605H26.5839C26.9999 24.3605 27.1679 24.4466 27.3319 24.8323C29.9559 30.4733 32.6239 36.2866 35.1239 41.9687H35.2079C37.6239 36.3277 40.2919 30.4733 42.8759 24.8733C43.0839 24.5328 43.2079 24.3195 43.6279 24.3195H50.3359C50.6679 24.3195 50.7959 24.5779 50.5879 24.9184L39.5399 46.7933ZM53.2519 53.7594C53.2519 43.8025 59.7119 36.0241 69.9599 36.0241C70.8359 36.0241 71.2919 36.0241 72.1279 36.1102V24.7872C72.1279 24.5287 72.3359 24.3605 72.5439 24.3605H79.1279C79.4599 24.3605 79.5439 24.4877 79.5439 24.701V63.9338C79.5439 65.0866 79.5439 66.5389 79.7519 68.1225C79.7519 68.381 79.6679 68.463 79.4199 68.5943C75.9199 70.3051 72.2519 71.0723 68.7519 71.0723C59.7079 71.1092 53.2479 65.3861 53.2519 53.7594ZM72.1239 42.822C71.5399 42.5636 70.7079 42.3953 69.7079 42.3953C64.4559 42.3953 60.7919 46.5389 60.7919 53.423C60.7919 61.2876 64.5439 64.4507 69.2519 64.4507C70.2519 64.4507 71.3359 64.3235 72.1279 63.9789V42.822H72.1239Z"
          fill="#F1EFFD"
        />
      </svg>
      <svg
        id={`chrome-icon-${props.id}`}
        height={height}
        viewBox="0 0 126 101"
        fill="none"
        onClick={() => redirect(APP_SIGN_UP_URL, "Chrome hero")}
        style={{ cursor: "pointer" }}
      >
        <title>Chrome Browser</title>
        <path
          d="M49.9174 0.0136933C49.9174 0.0136933 79.3393 -1.30662 94.9381 28.2668H47.4139C47.4139 28.2668 38.4451 27.9778 30.7838 38.8582C28.583 43.425 26.2174 48.1293 28.8721 57.4004C25.0479 50.9215 8.56934 22.2285 8.56934 22.2285C8.56934 22.2285 20.1924 1.18283 49.917 0.0136933H49.9174Z"
          fill="#5940E3"
        />
        <path
          d="M93.4112 75.0894C93.4112 75.0894 79.8347 101.238 46.4375 99.9449C50.5641 92.8058 70.2062 58.7895 70.2062 58.7895C70.2062 58.7895 74.9519 51.1691 69.3535 39.0922C66.5058 34.8969 63.6035 30.509 54.2504 28.1703C61.7746 28.102 94.8557 28.1703 94.8557 28.1703C94.8557 28.1703 107.249 48.7621 93.4112 75.0894Z"
          fill="#7864E8"
        />
        <path
          d="M6.63015 75.2958C6.63015 75.2958 -9.22961 50.4676 8.59695 22.1871C12.7098 29.3262 32.352 63.3426 32.352 63.3426C32.352 63.3426 36.5887 71.2657 49.8348 72.4481C54.8833 72.077 60.1376 71.7606 66.8505 64.8419C63.1497 71.3895 46.547 100 46.547 100C46.547 100 22.5032 100.44 6.62976 75.2958H6.63015Z"
          fill="#9B8CEE"
        />
        <path
          d="M46.4238 100.193L53.1086 72.2973C53.1086 72.2973 60.454 71.7191 66.6165 64.9656C62.7923 71.6922 46.4238 100.193 46.4238 100.193V100.193Z"
          fill="#9B8CEE"
        />
        <path
          d="M27.5244 50.4125C27.5244 38.1157 37.4971 28.1431 49.7939 28.1431C62.0908 28.1431 72.0635 38.1157 72.0635 50.4125C72.0635 62.7098 62.0908 72.682 49.7939 72.682C37.4971 72.6683 27.5244 62.7098 27.5244 50.4125V50.4125Z"
          fill="white"
        />
        <path
          d="M31.252 50.4124C31.252 40.1788 39.5465 31.8706 49.7942 31.8706C60.0278 31.8706 68.336 40.1652 68.336 50.4124C68.336 60.6464 60.0418 68.9547 49.7942 68.9547C39.5602 68.9547 31.252 60.6464 31.252 50.4124V50.4124Z"
          fill="#5940E3"
        />
        <path
          d="M94.842 28.1844L67.3177 36.2586C67.3177 36.2586 63.1638 30.1648 54.2368 28.1844C61.981 28.143 94.842 28.1844 94.842 28.1844V28.1844Z"
          fill="#7864E8"
        />
        <path
          d="M28.3357 56.3825C24.4701 49.6837 8.56934 22.2285 8.56934 22.2285L28.9545 42.3934C28.9545 42.3934 26.8635 46.6989 27.6479 52.861L28.3354 56.3825H28.3357Z"
          fill="#C3BAF5"
        />
      </svg>
    </PluginsContainer>
  );
};

export default AvailablePlugins;
