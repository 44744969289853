import React from "react";
import {
  ABTestIcon,
  PreferenceTest,
  Diamond,
  ValidateAssumptions,
  TestProduct,
  Eyes
} from "./Icons";

export default [
  { icon: <TestProduct />, text: "Test your new product" },
  { icon: <ABTestIcon />, text: "A/B test your designs" },
  { icon: <PreferenceTest />, text: "Conduct rapid preference tests" },
  { icon: <ValidateAssumptions />, text: "Validate your assumptions" },
  { icon: <Eyes />, text: "Understand what users are looking at" },
  { icon: <Diamond />, text: "Reduce clutter in your designs" }
];

// NEW ICONS
// import TestProductIcon from "./Icons/TestProductIcon";
// import ABTestIcon from "./Icons/ABTestIcon";
// import PreferenceTestIcon from "./Icons/PreferenceTestIcon";
// import ValidateAssumptionsIcon from "./Icons/ValidateAssumptionsIcon";
// import WhereUsersLookIcon from "./Icons/WhereUsersLookIcon";
// import ReduceClutterIcon from "./Icons/ReduceClutterIcon";

// export default [
//   { icon: <TestProductIcon size={100} />, text: "Test your new product" },
//   { icon: <ABTestIcon size={100} />, text: "A/B test your designs" },
//   {
//     icon: <PreferenceTestIcon size={100} />,
//     text: "Conduct rapid preference tests"
//   },
//   {
//     icon: <ValidateAssumptionsIcon size={100} />,
//     text: "Validate your assumptions"
//   },
//   {
//     icon: <WhereUsersLookIcon size={100} />,
//     text: "Understand what users are looking at"
//   },
//   {
//     icon: <ReduceClutterIcon size={100} />,
//     text: "Reduce clutter in your designs"
//   }
// ];
