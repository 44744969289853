import React from "react";
import SectionLayout from "../../components/SectionLayout";
import data from "./data.js";
import { Card, CardsWrapper } from "./UseCases.style.js";
import Heading from "reusecore/src/elements/Heading";

const UseCases = () => {
  return (
    <SectionLayout
      sectionId="use-cases"
      title="What can you do with VisualEyes?"
      style={{ paddingTop: 0, paddingBottom: "80px" }}
      as="h2"
    >
      <CardsWrapper>
        {data.map((useCase, index) => (
          <Card id={index}>
            {useCase.icon}
            <Heading as="h3" content={useCase.text} />
          </Card>
        ))}
      </CardsWrapper>
    </SectionLayout>
  );
};

export default UseCases;
