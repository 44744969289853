import styled from "styled-components";
import { themeGet } from "styled-system";

const HeroWrapper = styled.section`
  background-color: #fff;
  padding-top: 160px;
  padding-bottom: 40px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 80vh;
  position: relative;
  overflow: hidden;

  display: flex;
  align-items: center;
  @media (max-width: 990px) {
    padding-top: 180px;
    padding-bottom: 30px;
    min-height: auto;
  }
  @media (max-width: 767px) {
    padding-top: 130px;
    padding-bottom: 20px;
    min-height: auto;
    & > div > div {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column-reverse;
    }
  }

  @media only screen and (max-width: 480px) {
    background: none;

    & > div > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .particle {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    @media (max-width: 767px) {
      display: none;
    }
    @media only screen and (max-width: 480px) {
      width: 100%;
    }
  }

  .row {
    position: relative;
    z-index: 1;
  }

  .button__wrapper {
    margin-top: 40px;
    @media (max-width: 767px) {
      margin-bottom: 30px;
    }
    .reusecore__button {
      &.outlined {
        border-color: #3e21de;
      }
    }
  }
`;

const HeroObject = styled.div`
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;

  align-self: flex-start;

  ${"" /* margin-top: 4rem; */}

  @media (max-width: 1200px) {
    margin-top: 0px;
  }
  @media (max-width: 767px) {
    margin: auto;
    margin-top: 0px;
    width: 70%;
    position: unset;
  }
  @media (max-width: 500px) {
    margin-top: 0px;
    width: 100%;
  }
  .objectWrapper {
    margin-right: auto;
    position: relative;
    height: 80%;
    top: 50%;
    transform: translateY(-50%);

    @media (max-width: 767px) {
      ${"" /* display: none; */}
      transform: translateY(0);
      positino: unset;
    }

    img {
      max-height: 100%;
      margin: auto;
    }

    .dashboardWrapper {
      position: absolute;
      top: 0;
      right: 0;
      .chatObject {
        position: absolute;
        top: 20px;
        left: 120px;
      }
    }
  }
`;

const DiscountLabel = styled.div`
  display: inline-block;
  border-radius: 4em;
  border: 1px solid ${themeGet("colors.lightBorder", "#f1f4f6")};
  padding: 7px 25px;
  box-shadow: 0px 7px 25px 0px rgba(22, 53, 76, 0.05);
  margin-bottom: 30px;
  background-color: ${themeGet("colors.white", "#ffffff")};
  @media (max-width: 767px) {
    padding: 7px 16px;
  }
`;

export { DiscountLabel, HeroObject };

export default HeroWrapper;
