import React from "react";
import PropTypes from "prop-types";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import Box from "reusecore/src/elements/Box";
import Text from "reusecore/src/elements/Text";
import Heading from "reusecore/src/elements/Heading";
import Card from "reusecore/src/elements/Card";
import Button from "reusecore/src/elements/Button";
import Image from "../../../components/Image";
import FeatureBlock from "../../../components/FeatureBlock";
import Container from "../../../components/Container";
import BlockWrapper, { ContentWrapper } from "./Block.style";
import { FiArrowRight } from "react-icons/fi";
import { buttonStyle } from "reusecore/src/theme/customVariant";
import { navigate } from "gatsby";
import redirect from "../../../utils/redirect";
import { gaVisitor } from "../../../utils/analytics";

const Block = ({
  titleStyle,
  subtitleStyle,
  descriptionStyle,
  textAreaStyle,
  imageWrapper,
  title,
  subtitle,
  content,
  index,
  left,
  image,
  additional,
  ctaText,
  to,
  eventName,
  external,
  icon,
}) => {
  return (
    <BlockWrapper id={`feature-${index}`} index={index}>
      <Fade bottom>
        <div style={{ marginBottom: "1.5rem" }}>{icon}</div>
      </Fade>

      <ContentWrapper>
        <FeatureBlock
          title={<Heading as="h3" content={title} {...titleStyle} />}
          subtitle={<Heading as="h4" content={subtitle} {...subtitleStyle} />}
          description={
            <>
              {content.map((text) => (
                <Text content={text} {...descriptionStyle} />
              ))}
            </>
          }
          additionalContent={additional}
        />
      </ContentWrapper>

      <Button
        id={`btn-personas-${title.split(" ").join("-")}`}
        title={ctaText}
        icon={<FiArrowRight />}
        variant="textButton"
        iconPosition="right"
        onClick={() => {
          gaVisitor(eventName);
          external ? redirect(to, eventName) : navigate(to);
        }}
        style={{
          textTransform: "capitalize",
          fontSize: "18px",
          marginTop: "1rem",
          alignSelf: "flex-start",
        }}
      />
    </BlockWrapper>
  );
};

Block.propTypes = {
  titleStyle: PropTypes.object,
  descriptionStyle: PropTypes.object,
  btnStyle: PropTypes.object,
};

Block.defaultProps = {
  imageWrapper: {
    position: "relative",
    boxShadow: "none",
    width: "100%",
  },
  titleStyle: {
    fontSize: ["24px, 40px"],
    fontWeight: "900",
    color: "#32325d",
    mb: "8px",
    letterSpacing: "-0.010em",
    maxWidth: ["100%", "100%", "100%", "440px", "440px"],
    lineHeight: "1.5",
  },
  subtitleStyle: {
    fontSize: ["18px", "20px"],
    fontWeight: "400",
    color: "rgba(52, 61, 72, 0.5)",
    mb: "32px",
    maxWidth: ["100%", "100%", "100%", "440px", "440px"],
    lineHeight: "1.5",
  },
  descriptionStyle: {
    fontSize: "20px",
    color: "#343d48cc",
    lineHeight: "1.8",
    mb: "1rem",
    maxWidth: ["100%", "100%", "100%", "440px", "440px"],
  },
};

export default Block;
