import React from "react";
import { FiArrowRight, FiHome } from "react-icons/fi";
import { navigate } from "gatsby";
import redirect from "../../utils/redirect";
import { OriginalIcon, AttentionIcon, ClarityIcon, AOIIcon } from "./Icons";

export default {
  demo: [
    // {
    //   id: "original",
    //   name: "Original Image",
    //   content:
    //     "Upload your design in our platform, or use our plugins, and analyze it with one click.",
    //   image: "Demo - Original.jpg",
    //   url:
    //     "https://d34cuim5v38p5e.cloudfront.net/visualeyes/website/Demo+-+Original.jpg",
    //   icon: <OriginalIcon size={32} />,
    // },
    {
      id: "attention",
      name: "Attention Map",
      content:
        "Attention Maps are the perfect replacement for Five seconds tests and Eye Tracking studies.",
      image: "Demo - Attention.jpg",
      url:
        "https://d34cuim5v38p5e.cloudfront.net/visualeyes/website/Demo+-+Attention.jpg",
      icon: <AttentionIcon size={32} />,
    },
    {
      id: "clarity",
      name: "Clarity Score",
      content:
        "The Clarity score can also be a great substitute for a quick Preference Test.",
      image: "Demo - Clarity.jpg",
      url:
        "https://d34cuim5v38p5e.cloudfront.net/visualeyes/website/Demo+-+Clarity.jpg",
      icon: <ClarityIcon size={32} />,
    },
    {
      id: "aoi",
      name: "Areas of Interest",
      content:
        "Areas of interest quantify attention inside critical elements and enable quick AB tests.",
      image: "Demo - AOI.jpg",
      url:
        "https://d34cuim5v38p5e.cloudfront.net/visualeyes/website/Demo+-+AOI.jpg",
      icon: <AOIIcon size={32} />,
    },
  ],
};
